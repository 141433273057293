.checkout_info_bubble {
  border: 1px solid #646464;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  overflow: auto;
  font-size: 16px;
}
.cartItemName {
  width: 70%;
  float: left;
  text-align: left;
  margin-bottom: 10px;
}
.cartItemInfo {
  font-size: 12px;
}
.cartItemPrice {
  width: 30%;
  float: left;
  overflow: hidden;
  text-align: right;
}

.confirmButton {
  width: 180px;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #646464;
  border-radius: 5px;
  background-color: #fffef7;
  color: #646464;
  cursor: pointer;
}

.cartItem {
  overflow: auto;
}

.cartTotal {
  border-top: 1px solid #646464;
  padding-top: 15px;
  margin-top: 10px;
}
