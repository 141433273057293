.address_collector_bubble {
  border: 1px solid #646464;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  overflow: auto;
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
}
.collectorFormInput {
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  width: calc(50% - 32px);
  color: #646464;
  border: 1px solid #646464;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
}
select[name="rcrs-country"] {
  width: calc(100% - 10px);
}
.collectorFormInput:nth-child(odd) {
}
.bubble_title {
  margin-bottom: 5px;
  cursor: pointer;
}
.completeIcon {
  position: absolute;
  right: 15px;
  top: 13px;
}
.shieldIcon {
  position: absolute;
  right: 35px;
  top: 13px;
}
.addrInputWithErrors {
  border: 1px solid red;
}

input[name="fname"] {
  margin-top: 10px;
}
