.checkoutProcess {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
}
.background_image {
  width: 50%;
  float: left;
  height: 100vh;
  background-size: cover;
  background-position: 50%;
}
.success_bubble {
  overflow: auto;
  max-width: 400px;
  margin-bottom: 20px;
}
.success_bubble .success_bubble_icon {
  width: 20%;
  float: left;
  font-size: 35px;
  color: green;
}
.success_bubble .success_bubble_message {
  width: 80%;
  float: left;
  text-align: left;
}
.checkout_bubbles {
  max-width: 400px;
}

@media only screen and (max-width: 800px) {
  .background_image {
    width: 100%;
  }
  .checkoutProcess {
    width: 100%;
    left: 0%;
  }
  .checkout_bubbles {
    max-width: auto;
    padding: 20px;
    background: #ffffff9e;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    color: black;
    width: calc(100% - 80px);
  }
}
